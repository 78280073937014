import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./chat.css";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils";
import { Form, Button } from "react-bootstrap";
import { useRef } from "react";
import axios from "axios";

const Chat = () => {
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const middleRef = useRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const [supportList, setSupportList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [click, setClick] = useState(0);
  const [color, setColor] = useState("#969595");
  const [uploadedFile, setUploadedFile] = useState(null);

  const [count, setCount] = useState(null);
  const { supportId } = useParams();

  const navigate = useNavigate();

  const [id, setId] = useState(supportId);
  const [comment, setComment] = useState("");
  // console.log(id);

  const [formData, setFormData] = useState([]);

  const handleImageClick = () => {
    // Trigger click on the hidden file input
    fileInputRef.current.click();
    // console.log(fileInputRef.current);
  };

  const handleFileChange = async (event) => {
    // Handle file change here (e.g., upload file, display filename, etc.)
    const file = event.target.files[0];

    if (!file) {
      showToast("Please Select Image", "error", "error");
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const base64String = await convertToBase64(file);
    const formDataToSubmit = {
      fileUpload: base64String,
      fileName: file.name,
    };

    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/upload/encodedDocument",
        { ...formDataToSubmit },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode == "200") {
          showToast("file Inserted !", "success", "success");
          setUploadedFile(response.data.documentUrl);
        }
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
  };

  const fetchSupportDetails = async (id) => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/support/details`, {
        adminToken: token,
        supportId: id,
      });
      navigate(`/admin/support/view_chat/${id}`);
      setDetailData(response?.data?.message);

      setError("");
    } catch (error) {
      setError("Failed to fetch support details");
      console.error("Error fetching support details for edit:", error);
    }
  };

  useEffect(() => {
    fetchSupportDetails(id);
  }, [id]);

  const fetchSupportList = (status) => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/support/getList", {
      adminToken: token,
      status: status,
    })
      .then((response) => {
        setSupportList(response?.data?.message?.tickets);
        setTempData(response?.data?.message?.tickets);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching User list:", error);
      });
  };
  useEffect(() => {
    fetchSupportList("");
  }, []);

  const handleStatus = (val) => {
    if (val === 3) {
      setCount(3);
      setColor("success");
      fetchSupportList("Resolved");
    } else if (val === 2) {
      setCount(2);
      setColor("danger");
      fetchSupportList("OPEN");
    } else {
      setCount(1);
      fetchSupportList("");
    }
  };

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...supportList]);
    } else {
      const filteredItems = supportList.filter(
        (item) =>
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.supportId &&
            item.supportId.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.subject &&
            item.subject.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  const handleClick = (val) => {
    // navigate(`/support/view_chat/${val}`);
    setId(val);
    fetchSupportDetails(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // alert("hii");
    setClick(1);
    const now = new Date();
    const formattedDateTime = `${now.getFullYear()}-${String(
      now.getMonth() + 1
    ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(
      now.getSeconds()
    ).padStart(2, "0")}`;

    setFormData((prevFormData) => [
      ...prevFormData,
      {
        supportId: id,
        comment: comment,
        date: formattedDateTime,
      },
    ]);

    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/chat/message`, {
        adminToken: token,
        supportId: id,
        comment: comment,
      });
      if (response.data.resCode === "200") {
        showToast("Query Send  Succesfully!", "success", "success");
        setTimeout(() => {
          setComment("");
          navigate(`/admin/support/view_chat/${id}`);
          fetchSupportDetails(id);
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update support details");
      console.error("Error updating support details:", error);
    }

  };

  const handleStatusUpdate = (id) => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/support/update", {
      adminToken: token,
      supportId: id,
      status: "Resolved"
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Ticket Resolved!", "success", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          console.log(response.data.resMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating the ticket status:", error);
      });
  }

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [detailData]);

  const scrollToMiddle = () => {
    if (middleRef.current) {
      middleRef.current.scrollTop = middleRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToMiddle();
  }, [id]);

  return (
    <div className="admin_support_layout">
      <Row>
        <Col className="col-xl-5  col-lg-5 col-sm-5 col-12 ">
          <div className="chat_support main ">
            <h5 className="text-center title"> All Tickets</h5>
            <div className="mid_line mb-3">
              <Link
                onClick={() => handleStatus(1)}
                className={` ${count === 1 ? "text-primary" : "stat"}`}
              >
                {" "}
                <div className="status ">ALL</div>
              </Link>
              <Link
                onClick={() => handleStatus(2)}
                className={` ${count === 2 ? "text-danger" : "stat"} `}
              >
                <div className="status">OPEN</div>
              </Link>
              <Link
                onClick={() => handleStatus(3)}
                className={` ${count === 3 ? "text-success" : "stat"} `}
              >
                {" "}
                <div className="status">CLOSED</div>{" "}
              </Link>
            </div>

            <div className="search_box mb-3">
              <div className="search_chat  rounded ">
                <input
                  placeholder="Search"
                  type="search"
                  aria-controls="datatable"
                  onChange={filterData}
                />
                <img
                  className={""}
                  src="/build/img/search.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
              </div>
            </div>

            {tempData.map((val) => {
              return (
                <>
                  <Link onClick={() => handleClick(val.supportId)}>
                    <Row
                      className={`content_box rounded m-1 p-1 mb-2 ${val.supportId === id ? "box_styling" : "content_box"
                        }`}
                    >
                      <div className="content"> Name:-{val.userName}</div>
                      <div className="content">Subject:-{val.subject}</div>
                      <div className="content">TicketNo:-{val.supportId}</div>
                      <div
                        className={`mid_line ${val.supportId === id ? "box_styling" : "content_box"
                          }`}
                      >
                        <div className="content">Date:-{val.createdOn}</div>
                        <div className=" flex">
                          Status:-
                          <span
                            className={`${val.supportId === id
                              ? "text-white"
                              : val.status === "OPEN"
                                ? "text-danger"
                                : val.status === "Resolved"
                                  ? "text-success"
                                  : null
                              }`}
                          >
                            {" "}
                            {val.status}
                          </span>
                        </div>
                      </div>
                    </Row>
                  </Link>
                </>
              );
            })}
          </div>
        </Col>
        <Col className="col-xl-7  col-lg-7 col-sm-7 col-12">
          <div className="page_heading  chat_client">
            {/* <h4>View Chat </h4> */}

            <div className="second_div  ">
              <Link to="#">
                <button
                  className="btn btn-new_1"
                  onClick={() => window.location.reload()}
                >
                  {" "}
                  Refresh{" "}
                </button>
              </Link>
              &nbsp; &nbsp;
              <Link to="/admin/manage_support">
                <span style={{ cursor: "pointer" }}>
                  <div className="backbutton"></div>
                </span>
              </Link>
            </div>
          </div>

          <div className="chat_support1  p-5 message">
            <Row className="mb-3">
              <strong>{detailData.userName}</strong>
              <div>
                Subject:-{detailData.subject} | {detailData.createdOn}
              </div>
            </Row>
            {detailData.status == "OPEN" &&
              <Row>
                <div className="mark1" onClick={() => handleStatusUpdate(detailData.supportId)}>
                  <p className="btn btn-dark">Mark as Resolved</p>
                </div>
              </Row>
            }
            <Row
              className="main online_chating"
              style={{ height: "250px" }}
              ref={chatContainerRef}
            >
              {detailData?.chat
                ?.sort((a, b) => a.chatId - b.chatId)
                .map((val) => {
                  if (val.userType === "user") {
                    return (
                      <Row key={val.chatId} className="mb-3 support">
                        <Col className="col-12 mb-2">
                          <div>
                            {val.userName} {val.createdOn}
                          </div>
                          <div className="pt-1">
                            <span
                              className="p-1 rounded"
                              style={{ backgroundColor: "#a9a9a952" }}
                            >
                              {val.comment}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    );
                  } else if (val.userType === "Admin") {
                    return (
                      <Row key={val.chatId} className="mb-3 client">
                        <Col className="col-12 mb-2">
                          <div className="chat_client">
                            Support Team {val.createdOn}
                          </div>
                          <div className="chat_client">
                            <span
                              className="p-1 rounded"
                              style={{ backgroundColor: "bisque" }}
                            >
                              {val.comment}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    );
                  }
                })}
            </Row>

            <Row className="pt-5">
              <div className="chat  rounded flex  ">
                <img
                  className={""}
                  src="/build/chat.jpg"
                  alt="zex pr wire Logo"
                  width={30}
                  height={30}
                  priority
                />
                &nbsp;
                <input
                  placeholder="Enter Your text"
                  type="text"
                  aria-controls="datatable"
                  value={comment}
                  //  onChange={filterData}

                  onChange={(e) => setComment(e.target.value)}
                />
                <div>
                  {/* Hidden file input */}

                  {/* Image that acts as the file input trigger */}
                  <img
                    src="/build/Attach.png"
                    alt="file Logo"
                    width={14}
                    height={26}
                    onClick={handleImageClick}
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
                {/* </Link> */}
                &nbsp; &nbsp;
                <Link
                  onClick={comment.length >= 1 ? handleSubmit : (e) => e.preventDefault()}
                >
                  <img
                    className={""}
                    src="/build/Send.png"
                    alt="zex pr wire Logo"
                    width={24}
                    height={24}
                    priority
                  />
                </Link>
              </div>
            </Row>
            {comment.length <= 0 && !file && click === 1 ? (
              <div className="text-danger mt-3">
                * Please Enter something !{" "}
              </div>
            ) : null}

            {file ? <div className=" mt-3">{file}</div> : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Chat;
